// Colors
$theme: #3F51B5;
$themeLight: #F0F1FF;

// Transitions
$bc3s: background-color .3s ease;
$c3s: color .3s ease;
$o3s: opacity .3s ease;
$v3s: visibility .3s ease;

.container {
  position: relative;
  text-align: center;
}

.hoverContainer {
  position: relative;
  min-width: 100px;
}

.hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  opacity: 0;
  visibility: hidden;
  transition: $o3s, $v3s;

  align-items: center;
  background-color: rgba(0, 0, 0, .65);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.hoverVisible {
  opacity: 1;
  visibility: visible;
}

.preview, .download, .remove {
  color: #fff;
  cursor: pointer;
  margin: 0;
}

.preview {
  margin-bottom: 4px;
}

.remove, .download {
  position: absolute;
}

.download {
  bottom: 6px;
  right: 6px;
}

.remove {
  bottom: 6px;
  right: 37px;
}

.popIcon {
  color: $theme !important;
}

.previewIcon {
  font-size: 16px;
  margin-right: 4px;
  transform: translateY(1px);
}

.downloadIcon, .removeIcon {
  background-color: rgba(0, 0, 0, .6);
  border-radius: 50%;
  font-size: 16px;
  padding: 6px;
  transition: $bc3s;

  &:hover, &:active {
    background-color: #000;
  }
}

.fileItem, .imageItem {
  height: 120px !important;
}

.image {
  height: 120px !important;
}

.fileItem {
  align-items: center;
  background-color: rgb(230,230,230);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 140px;
}

.fileIcon {
  font-size: 48px;
  padding-top: 6px;
}

.imageItem {
  user-select: none;
}

.fileName {
  font-size: 13px;
  font-weight: 500;
  line-height: 120%;
  margin-top: 10px;
  text-align: center;
  max-width: 100px;
  word-break: break-word;
}

.note {
  bottom: -3px;
  left: 0;
  // display: block;
  font-size: 14px;
  position: absolute;
  z-index: 3;
  margin-left: 50%;
  transform: translateX(-50%);
}

.previewImage {
  display: none !important;
  margin: 0 !important;
}
