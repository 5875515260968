// Colors
$theme: #3F51B5;
$themeLight: #F0F1FF;

// Transitions
$bc3s: background-color .3s ease;
$c3s: color .3s ease;
$o3s: opacity .3s ease;
$v3s: visibility .3s ease;

.container {
  font-size: 16px;
  width: 100%;
}

.label {
  font-size: 12px;
}

.input, .select, .date, .time, .textarea, .number {
  width: 100%;
}

.option {
  max-width: 600px !important;
}

.checkbox {

}

.number {
  display: block;
  width: 100% !important;
}
