// Colors
$theme: #3F51B5;
$themeLight: #F0F1FF;

// Transitions
$bc3s: background-color .3s ease;
$c3s: color .3s ease;
$o3s: opacity .3s ease;
$v3s: visibility .3s ease;

.container {
  align-items: center;
  background-color: $themeLight;
  border-bottom: 2px solid $theme;
  display: flex;
  padding: 8px 14px 6px 14px;

  > span {
    font-size: 18px;
    margin-right: 20px;
  }
}

.select {
  border-bottom: 1px solid #ccc !important;
  font-size: 16px !important;
  min-width: 160px !important;
}
