// Colors
$theme: #3F51B5;
$themeLight: #F0F1FF;

// Transitions
$bc3s: background-color .3s ease;
$c3s: color .3s ease;
$o3s: opacity .3s ease;
$v3s: visibility .3s ease;

.tabs {
  // background-color: $theme;
}

.helpButton {
  color: $theme !important;
  margin-right: 10px;

  svg {
    font-size: 22px !important;
  }
}

:global(.header-tabs) {
  > div {
    margin: 0 !important;

    > div > div {
      width: 100%;
    }
  }
}

:global(.header-tabs .ant-tabs-tab) {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 !important;
  padding: 11px 16px 10px 16px !important;
  width: 22%;

  div {
    color: #888 !important;
    font-size: 14px !important;
    line-height: 100%;
    text-transform: uppercase;
  }
}

:global(.header-tabs .ant-tabs-tab-active) {
  background-color: $theme !important;

  div {
    color: #fff !important;
    // font-weight: 500;
  }
}
