// Colors
$theme: #3F51B5;
$themeLight: #F0F1FF;

// Transitions
$bc3s: background-color .3s ease;
$c3s: color .3s ease;
$o3s: opacity .3s ease;
$v3s: visibility .3s ease;

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}
