// Colors
$theme: #3F51B5;
$themeLight: #F0F1FF;

// Transitions
$bc3s: background-color .3s ease;
$c3s: color .3s ease;
$o3s: opacity .3s ease;
$v3s: visibility .3s ease;

.mailrecords__open-attachments {
  background-color: #eee;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 6px;
  outline: none;
  padding: 6px 12px;
  transition: background-color .2s ease, color .2s ease;
}

.mailrecords__open-attachments:hover {
  background-color: #ddd;
}

.mailrecords__open-attachments:active {
  background-color: #666;
  color: #fff;
}

.mailrecords__comment {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 12px;
  padding: 12px;
  position: relative;
  max-width: 600px;
  width: 100%;
}

.mailrecords__comment p {
  border-bottom: 1px solid #ddd;
  font-weight: 600;
  margin: 0;
  padding: 2px 12px 12px 12px;
}

.mailrecords__comment textarea {
  border: none;
  box-sizing: border-box;
  display: block;
  font-family: inherit;
  font-size: 16px;
  height: 90px;
  padding: 12px;
  margin-bottom: 10px;
  resize: none;
  width: 100%;
}

.mailrecords__attachments-container {
  align-items: center;
  bottom: 8px;
  display: flex;
  flex-direction: row;
  padding: 0 4px;
  position: absolute;
  width: 100%;
}

.mailrecords__attachments-button {
  background: transparent;
  border: none;
  color: $theme;
  cursor: pointer;
  outline: none;
}

.mailrecords__attachments-button:hover, .mailrecords__attachments-button.active {
  text-decoration: underline;
}

.mailrecords__attachment {
  background-color: #eee;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 6px 8px;
  transition: color .2s ease, background-color .2s ease;
  max-width: 520px;
}

.mailrecords__attachment:hover {
  background-color: #ddd;
}

.mailrecords__attachment:active {
  background-color: #666;
  color: #fff;
}

.mailrecords__button {
  background-color: #ccc;
  border: none;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  padding: 12px 16px;
}

.mailrecords__submit {
  background-color: rgb(63, 81, 181);
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  margin: 0 6px 0 0;
  padding: 8px 24px;
  transition: opacity .2s ease;
}

.mailrecords__submit--disabled {
  cursor: not-allowed;
  opacity: .3;
}

.mailrecords__handover {
  /* background-color: rgb(63, 81, 181); */
  background-color: #fff;
  border: 1px solid #52c41a;
  border-radius: 4px;
  color: #52c41a;
  cursor: pointer;
  font-size: 14px;
  margin: 0 6px 0 0;
  outline: none;
  padding: 8px 24px;
  transition: color .2s ease, background-color .2s ease;
}

.mailrecords__handover:hover {
  background-color: #eee;
}

.mailrecords__handover:active {
  background-color: #52c41a;
  color: #fff;
}

.mailrecords__close {
  /* background-color: rgb(63, 81, 181); */
  background-color: #fff;
  border: 1px solid #ab6100;
  border-radius: 4px;
  color: #ab6100;
  cursor: pointer;
  font-size: 14px;
  margin: 0;
  outline: none;
  padding: 8px 24px;
  transition: color .2s ease, background-color .2s ease;
}

.mailrecords__close:hover {
  background-color: #eee;
}

.mailrecords__close:active {
  background-color: #ab6100;
  color: #fff;
}
