// Colors
$theme: #3F51B5;
$themeLight: #F0F1FF;

// Transitions
$bc3s: background-color .3s ease;
$c3s: color .3s ease;
$o3s: opacity .3s ease;
$v3s: visibility .3s ease;

.container {
  margin-top: 10px;

  & > div > div {
    height: 120px !important;
    margin: 0 12px 25px 0 !important;
    position: relative;
    width: auto !important;
  }
}

.label {
  display: block;
  font-size: 12px;
  margin-bottom: -8px;
}

.uploadText {
  margin: 4px 0 0 0 !important;
}

.pasteButton {
  bottom: -55px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
