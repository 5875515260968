// Colors
$theme: #3F51B5;
$themeLight: #F0F1FF;

// Transitions
$bc3s: background-color .3s ease;
$c3s: color .3s ease;
$o3s: opacity .3s ease;
$v3s: visibility .3s ease;

.kzp-amount-input {
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  padding-bottom: 2px;
  width: 110px;
}

.edit-kzp-table {
  overflow-x: auto;
  width: 100%;
}
